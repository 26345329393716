import TopBanner from 'themes/base/components/content/TopBanner'

export default {
  extends: TopBanner,
  computed: {
    isVisible() {
      return (
        !this.$store.state.menu.mainMenuMobileOpened &&
        this.loaded &&
        this.banner &&
        this.banner.enabled &&
        this.$store.state.globals.topBannerLastModifiedClosed !== new Date(this.banner.lastModified).getTime()
      )
    },
  },
}
